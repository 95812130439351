import Header01 from "./header/Header01";
import dynamic from "next/dynamic";

const Footer = dynamic(() => import("./footer"));

export default function Layout({ children }) {
  return (
    <>
      <Header01 />
      <main>{children}</main>
      <Footer />
    </>
  );
}
