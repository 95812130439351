import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from './counterSlice';
import searchBoxReducer from './searchBoxSlice';

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		searchBox: searchBoxReducer
	},
});
