import Image from "next/image";
import Link from "next/link";
import DarkMode from "../mode/DarkMode";
import Logo from "./../../public/images/logo.png";
import WhiteLogo from "./../../public/images/logo_white.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import { isChildrenPageActive } from "../../utils/daynamicNavigation";
import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";

import unitedKingdomSvg from "./../../public/svgs/united_kingdom.svg";
import germanySvg from "./../../public/svgs/germany.svg";
import bulgariaSvg from "./../../public/svgs/bulgaria.svg";
import romaniaSvg from "./../../public/svgs/romania.svg";
import russiaSvg from "./../../public/svgs/russia.svg";

import paypalSvg from "./../../public/svgs/paypal.svg";
import stripeSvg from "./../../public/svgs/stripe.svg";
import revolutSvg from "./../../public/svgs/revolut.svg";
import visaSvg from "./../../public/svgs/visa.svg";
import masterCardSvg from "./../../public/svgs/mastercard.svg";

export default function Header01() {
  const { t } = useTranslation("common");
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const [langSwitcherToggle, setLangSwitcher] = useState(false);
  const [phoneOptionsToggle, setPhoneOptions] = useState(false);

  const toggleLanguageSwitcher = () => {
    setPhoneOptions(false);
    setLangSwitcher(!langSwitcherToggle);
  };

  const togglePhoneOptions = () => {
    setLangSwitcher(false);
    setPhoneOptions(!phoneOptionsToggle);
  };

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */
  const home = {
    id: 1,
    name: "Home",
    pages: [
      {
        id: uuidv4(),
        name: "Home 1",
        path: "/",
      },
    ],
  };

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return (
    <>
      {/* main desktop menu sart*/}
      <header className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors">
        <div className="flex items-center px-6 py-6 xl:px-24 ">
          <Link className="shrink-0" href="/">
            <a>
              <div className="dark:hidden">
                <Image
                  src={Logo}
                  height={28}
                  width={130}
                  alt="low cost sofia airport pickups - cabs"
                  className="max-h-7 h-auto "
                  loading="eager"
                />
              </div>
              <div className="hidden dark:block">
                <Image
                  src={WhiteLogo}
                  height={28}
                  width={130}
                  alt="cheap sofia airport transportation service, rent a minibus, car rental"
                  loading="lazy"
                />
              </div>
            </a>
          </Link>
          {/* End  logo */}
          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                {/* home */}
                <li className="js-nav-dropdown group relative">
                  <Link href="/">
                    <a>
                      <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, "/")
                              ? "text-accent dark:text-accent"
                              : ""
                          }
                        >
                          {t("nav.home")}
                        </span>
                      </button>
                    </a>
                  </Link>
                </li>
                <li className="group">
                  <Link href="/reservations">
                    <a>
                      <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, "/reservations")
                              ? "text-accent dark:text-accent"
                              : ""
                          }
                        >
                          {t("nav.my-bookings")}
                        </span>
                      </button>
                    </a>
                  </Link>
                </li>
                <Link href="/routes">
                  <a>
                    <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span
                        className={
                          isChildrenPageActive("/routes", route.asPath)
                            ? "text-accent dark:text-accent"
                            : ""
                        }
                      >
                        {t("nav.routes-and-prices")}
                      </span>
                    </button>
                  </a>
                </Link>
                <Link href="/book">
                  <a>
                    <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span
                        className={
                          isChildrenPageActive("/book", route.asPath)
                            ? "text-accent dark:text-accent"
                            : ""
                        }
                      >
                        {t("nav.book")}
                      </span>
                    </button>
                  </a>
                </Link>
                <Link href="/blog">
                  <a>
                    <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span
                        className={
                          isChildrenPageActive("/blog", route.asPath)
                            ? "text-accent dark:text-accent"
                            : ""
                        }
                      >
                        {t("nav.blog")}
                      </span>
                    </button>
                  </a>
                </Link>
              </ul>
            </nav>
            {/* End menu for desktop */}

            {/* End header right content  for desktop */}
          </div>
          {/* header menu conent end for desktop */}
          <div className="ml-auto flex">
            <button
              onClick={() => togglePhoneOptions()}
              className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="language"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                height="24"
                width="24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M10.5 18.75a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" />
                <path
                  fillRule="evenodd"
                  d="M8.625.75A3.375 3.375 0 005.25 4.125v15.75a3.375 3.375 0 003.375 3.375h6.75a3.375 3.375 0 003.375-3.375V4.125A3.375 3.375 0 0015.375.75h-6.75zM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 017.5 19.875V4.125z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {phoneOptionsToggle && (
              <div className="dropdown-menu dark:bg-jacarta-800 !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:right-[20px]  lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl">
                <a
                  href={`tel:${t("call-us.call-now-action")}`}
                  onClick={() => togglePhoneOptions()}
                  className="w-full dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height={22}
                    width={22}
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 3.75a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V5.56l-4.72 4.72a.75.75 0 11-1.06-1.06l4.72-4.72h-2.69a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="font-bold text-jacarta-700 mt-1 text-sm dark:text-white">
                    {t("call-us.call-now-btn")}
                  </span>
                </a>
                <a
                  href="viber://chat?number=359879149614"
                  onClick={() => togglePhoneOptions()}
                  className="w-full dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                >
                  <Image
                    loading="lazy"
                    height={22}
                    width={22}
                    src="/svgs/viber.svg"
                    alt="secure transportation to any town/city in bulgaria"
                  ></Image>
                  <span className="font-bold text-jacarta-700 mt-1 text-sm dark:text-white">
                    Viber chat
                  </span>
                </a>
                <a
                  href="https://wa.me/+359879149614"
                  onClick={() => togglePhoneOptions()}
                  className="w-full dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                >
                  <Image
                    width={22}
                    height={22}
                    src="/svgs/whatsapp.svg"
                    loading="lazy"
                    alt="bulgarian transportation company for tourists whatsapp"
                  />
                  <span className="font-bold text-jacarta-700 mt-1 text-sm dark:text-white">
                    Whatsapp chat
                  </span>
                </a>

                <button
                  onClick={() => togglePhoneOptions()}
                  className="justify-center w-full dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                >
                  <span className="font-bold text-red mt-1 text-sm">
                    {t("call-us.close-btn")}
                  </span>
                </button>
              </div>
            )}

            <button
              onClick={() => toggleLanguageSwitcher()}
              className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="language"
            >
              {route.locale === "en-GB" ? (
                <Image
                  height={24}
                  width={24}
                  src={unitedKingdomSvg}
                  alt="Best private chauffeur services sofia"
                ></Image>
              ) : route.locale === "de" ? (
                <Image
                  height={24}
                  width={24}
                  src={germanySvg}
                  alt="Hire a car with driver in Bulgaria, airport transportation price"
                ></Image>
              ) : route.locale === "bg" ? (
                <Image
                  height={24}
                  width={24}
                  src={bulgariaSvg}
                  alt="bulgaria to Romania, Greece, North Macedonia, Serbia transfers"
                ></Image>
              ) : route.locale === "ro" ? (
                <Image
                  height={24}
                  width={24}
                  src={romaniaSvg}
                  alt="Bulgaria to romania taxi price, sofia airport to durankulak price bucharest price"
                ></Image>
              ) : route.locale === "ru" ? (
                <Image
                  height={24}
                  width={24}
                  src={russiaSvg}
                  alt="bulgaria to Romania, Greece, North Macedonia, Serbia transfers"
                ></Image>
              ) : (
                ""
              )}
            </button>
            {langSwitcherToggle && (
              <div className="dropdown-menu dark:bg-jacarta-800 !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl">
                <Link href={route.asPath} locale={"en-GB"}>
                  <a
                    onClick={() => toggleLanguageSwitcher()}
                    className="w-full dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                  >
                    <Image
                      loading="lazy"
                      height={24}
                      width={24}
                      src={unitedKingdomSvg}
                      alt="closest airport to rila sofia, rila monastery direct transport"
                    ></Image>
                    <span className="font-bold text-jacarta-700 mt-1 text-sm dark:text-white">
                      English
                    </span>
                  </a>
                </Link>
                <Link href={route.asPath} locale={"de"}>
                  <a
                    onClick={() => toggleLanguageSwitcher()}
                    className="w-full dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                  >
                    <Image
                      loading="lazy"
                      height={24}
                      width={24}
                      src={germanySvg}
                      alt="transfer from sofia to bansko, sofia airport transfers, sofia to bansko transfer"
                    ></Image>
                    <span className="font-bold text-jacarta-700 mt-1 text-sm dark:text-white">
                      Deutsch
                    </span>
                  </a>
                </Link>
                <Link href={route.asPath} locale={"bg"}>
                  <a
                    onClick={() => toggleLanguageSwitcher()}
                    className="w-full dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                  >
                    <Image
                      loading="lazy"
                      height={24}
                      width={24}
                      src={bulgariaSvg}
                      alt="bulgarian tourism, bulgarian hiking, skiing, transport"
                    ></Image>
                    <span className="font-bold text-jacarta-700 mt-1 text-sm dark:text-white">
                      Български
                    </span>
                  </a>
                </Link>
                <Link href={route.asPath} locale={"ro"}>
                  <a
                    onClick={() => toggleLanguageSwitcher()}
                    className="w-full dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                  >
                    <Image
                      loading="lazy"
                      height={24}
                      width={24}
                      src={romaniaSvg}
                      alt="sofia airport to romania airport, bansko transfers, bansko shuttle, bansko ski pass"
                    ></Image>
                    <span className="font-bold text-jacarta-700 mt-1 text-sm dark:text-white">
                      Română
                    </span>
                  </a>
                </Link>
                <Link href={route.asPath} locale={"ru"}>
                  <a
                    onClick={() => toggleLanguageSwitcher()}
                    className="w-full dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                  >
                    <Image
                      loading="lazy"
                      height={24}
                      width={24}
                      src={russiaSvg}
                      alt="bulgarian tourism, bulgarian hiking, skiing, transport"
                    ></Image>
                    <span className="font-bold text-jacarta-700 mt-1 text-sm dark:text-white">
                      Русский
                    </span>
                  </a>
                </Link>
              </div>
            )}
            <DarkMode />
            <button
              className="lg:hidden js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>

          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? "nav-menu--is-open" : "hidden"
        }`}
      >
        <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
          <div className="dark:hidden">
            <Image
              src={Logo}
              height={28}
              width={130}
              alt="sofia welcome pickup, ski card prices, best of value taxies"
              className="max-h-7 h-auto "
            />
          </div>

          <div className="hidden dark:block">
            <Image
              src={WhiteLogo}
              height={28}
              width={130}
              alt="how to get apart hotel lucky bansko bansko bulgaria"
            />
          </div>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
        {/* mobile menu top header content */}

        <nav className="navbar w-full">
          <ul className="flex flex-col lg:flex-row mt-[100px]">
            <li
              className="js-nav-dropdown group relative"
              onClick={() => setToggle(false)}
            >
              <Link href="/">
                <a>
                  <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      {t("nav.home")}
                    </span>
                  </button>
                </a>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/reservations">
                <a>
                  <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/reservations", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      {t("nav.my-bookings")}
                    </span>
                  </button>
                </a>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/routes">
                <a>
                  <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/routes", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      {t("nav.routes-and-prices")}
                    </span>
                  </button>
                </a>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/book">
                <a>
                  <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/book", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      {t("nav.book")}
                    </span>
                  </button>
                </a>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/blog">
                <a>
                  <button className="text-jacarta-700 font-bold hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/blog", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      {t("nav.blog")}
                    </span>
                  </button>
                </a>
              </Link>
            </li>
          </ul>
        </nav>
        {/* End navbar mobile menu  */}

        <div className="mt-auto w-full lg:hidden">
          <hr className="dark:bg-jacarta-600 bg-jacarta-100 mt-5 mb-2 h-px border-0" />
          <div className="block mb-2">
            <p className="text-center text-xs font-bold">
              Payments made easy and securely with
            </p>
          </div>
          <div className="flex items-center justify-center space-x-5">
            <div className="group">
              <Image
                src={paypalSvg}
                width={50}
                height={50}
                alt="how to get to bansko ski resort, bansko digital nomads"
                loading="lazy"
              />
            </div>
            <div className="group">
              <Image
                src={stripeSvg}
                width={50}
                height={50}
                alt="how to get to green life ski spa resort bansko bulgaria"
                loading="lazy"
              />
            </div>
            <div className="group">
              <Image
                src={revolutSvg}
                width={50}
                height={50}
                alt="coworking bansko, to any bansko hotel prices"
                loading="lazy"
              />
            </div>
            <div className="group">
              <Image
                src={visaSvg}
                width={50}
                height={50}
                alt="	how to get to borovets bulgaria, borovets ski resort bulgaria"
                loading="lazy"
              />
            </div>
            <div className="group">
              <Image
                src={masterCardSvg}
                width={50}
                height={50}
                alt="pamporovo ski season, things to do in pamporovo bulgaria"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        {/* mt-10 w-full lg:hidden */}
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
}
