import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adults: 0,
  children: 0,
  arrivalDate: null,
  returnDate: null
};

export const searchBoxSlice = createSlice({
  name: "searchBox",
  initialState,
  reducers: {
    updateAdults: (state, action) => {
      state.adults = action.payload
    },
    updateChildren: (state, action) => {
      state.children = action.payload
    },
    updateArrivalDate: (state, action) => {
      state.arrivalDate = action.payload
    },
    updateReturnDate: (state, action) => {
      state.returnDate = action.payload
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  updateAdults,
  updateChildren,
  updateArrivalDate,
  updateReturnDate
} = searchBoxSlice.actions;

// Selector
export const getSearchBoxData = (state) => state.searchBox;

export default searchBoxSlice.reducer;


