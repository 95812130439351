import Head from "next/head";
import { useRouter } from "next/router";

const Meta = ({ title, desc, cannonicalSubPath }) => {
  const router = useRouter();
  const localePrefix =
    router.locale === "en-GB"
      ? ""
      : router.locale === "de"
      ? "/de"
      : router.locale === "bg"
      ? "/bg"
      : router.locale === "ro"
      ? "/ro"
      : router.locale === "ru"
      ? "/ru"
      : "";
  const canonicalUrl = `https://www.airport-transfersbg.com${
    localePrefix ? localePrefix : ""
  }${cannonicalSubPath}`;

  // Used for og:locale meta tag
  const localeOg =
    localePrefix === "" ? "en-GB" : localePrefix.replace("/", "");

  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <link rel="apple-touch-icon" href="/favicon.png"></link>
        <meta name="description" content={desc} />
        <link rel="canonical" href={canonicalUrl} key="canonical" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="fb:app_id" content="676605564092787" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={localeOg} />
        <meta
          property="og:title"
          content="Affordable Airport transfers from Airport Sofia to anywhere, good prices for airport taxi in Sofia "
        />
        <meta
          property="og:description"
          content="Airport transfers, bus, car rental, private chauffeur services and many more from and to Airport Sofia"
        />
        <meta
          property="og:image"
          content="https://www.airport-transfersbg.com/images/hero/hero.svg"
        />

        <meta
          name="twitter:title"
          content="Airport transfer from and to Sofia Airport"
        />
        <meta
          name="twitter:description"
          content="Secured transportation service from and to Airport Sofia to any location, dynamic price and price prer km"
        />
        <meta
          name="twitter:image"
          content="https://www.airport-transfersbg.com/images/hero/hero.svg"
        />
        <meta
          name="twitter:image:alt"
          content="Airport Taxi Transfer Sofia Airport, best car rental with driver in Bulgaria"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Sofia airport transfer, taxi and shuttle from and to anywhere",
  desc: "We offer affordable Sofia airport We offer affordable Sofia Airport Transfers and minicab services. From and To and Sofia Airport, Bansko, Sofia City Center, Borovets, Plovdiv and many more.",
};

export default Meta;
