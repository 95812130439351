import Head from "next/head";

const defaultData = {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  name: "Airport-TransfersBG",
  image: "https://www.airport-transfersbg.com/images/about/travel_2.webp",
  "@id": "https://www.airport-transfersbg.com",
  url: "https://www.airport-transfersbg.com",
  telephone: "+359 879 149614",
  address: {
    "@type": "PostalAddress",
    streetAddress: "булевард „Christopher Columbus“ 1, 1540 Sofia",
    addressLocality: "Sofia",
    postalCode: "1540",
    addressCountry: "359",
    addressRegion: "02",
  },
  priceRange: "30 - 100",
  sameAs: ["https://www.facebook.com/profile.php?id=61551206698833"],
  openingHoursSpecification: {
    "@type": "OpeningHoursSpecification",
    dayOfWeek: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    opens: "00:00",
    closes: "23:59",
  },
};

// This component is used to apply Schema.org SEO description
export default function SchemaData({ data = defaultData }) {
  return (
    <Head>
      <script
        key="structured-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  );
}
